import request from '../../utils/axios';


//获取活动时间
export function getJfConfigurationValue(data) {
    return request({
        url: '/bpis/common/getJfConfigurationValue',
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'application/json'
        },
    })
}
